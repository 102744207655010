/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

export default function Footer() {
    return (
        <footer className="footer-main">
            <div className="container">
                <div className="  mt-2 mb-2 text-center">
                    Copyright © 2021 <a href="#" className="fs-14 text-primary">Clediss</a> All rights reserved.
                </div>
            </div>
        </footer>

    )
}
