import dotenv from 'dotenv'
import axios from "axios";


dotenv.config()
const { REACT_APP_API_URL } = process.env;

//GET URL BACKEND 
const url = REACT_APP_API_URL

/**
 * 
 * @param {*} code 
 * @returns 
 */
 export async function addData( data ) {
    try {
        const response = await axios.post(url +"postFormulaire",data, {
            SameSite: "None",
            secure: true,
            IsEssential: true,
            withCredentials: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
