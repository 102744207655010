import React from 'react'

export default function HeaderFormulaire() {
    return (
        <div className="page-header mt-6">
        <div className="page-leftheader mb-2">
            <h4 className="page-title mb-0 text-azure">Enquête de satisfaction Client</h4>
            <small className="text-muted mt-0 fs-14">Cher client,</small>
            <hr className='hr1 mb-2' />
            <small className="text-muted mt-0 fs-14">Dans le but d’améliorer la qualité de nos services, et pour mieux répondre à vos attentes, nous souhaitons recueillir votre avis à travers ce questionnaire ; prière de nous réserver quelques minutes pour nous aider à mieux vous servir.
                <hr className='hr1' /> Le traitement de vos réponses nous aidera à améliorer nos prestations.</small>
            <hr className='hr1 mb-2' />
            <small className="text-muted mt-0 fs-14 ">En vous remerciant par avance, je vous prie de croire, cher Client, en l'expression de nos sincères salutations.
            </small>
        </div>
        <div className="page-rightheader">
            <div className="ml-3 ml-auto d-flex">


                <span className="mt-3 mt-md-0 pg-header">
                    <img src="../assets/images/photos/20944811.jpg" alt="image2" className="image" style={{ height: "auto", width: "600px" }} />

                </span>
            </div>
        </div>
    </div>
    )
}
