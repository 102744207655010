import { BrowserRouter ,Switch } from "react-router-dom";
import EmptyLayout from "./Layout/EmptyLayout";
import Formulaire from "./pages/Formulaire";
import AppRoute from "./Layout/AppRoute";

function App() {
  return (
    <BrowserRouter>
    <Switch>
        {/**-------------------------------- PAGE Auhtentification ------------------------------- */}
        <AppRoute layout={EmptyLayout} component={Formulaire} path="/" />
    </Switch>
    </BrowserRouter>
  );
}

export default App;
