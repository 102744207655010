/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

export default function EmptyHeader() {
    return (
        <div className=" mb-4" style={{backgroundColor:"#edeff5" }}>
            <div className='row'>
                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-4'/>

                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-4 '>
                    <div className='text-center mr-4 mt-2'>
                    <img
                        src="../assets/images/brand/logo.png"
                        alt="Dashlot logo"
                        className='image mr-4'
                        style={{height:"auto",width:"150px"}}
                    />
                    </div>
                
                </div>
                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-4'/>

               
            </div>
        </div>


    )
}
