/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react'
import HeaderFormulaire from './HeaderFormulaire'
import Swal from 'sweetalert2'
import { addData } from '../services/FormulaireApi';

export default function Formulaire() {

    const mail = useRef('')
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //verif mail

    // -----------Formualaire ----------
    const [email, setemail] = useState("")
    const [valide, setvalide] = useState("")
    const [valideNom, setvalideNom] = useState("")
    const [nomPrenom, setNomPrenom] = useState("")
    const [ste, setste] = useState("")
    const [q2, setq2] = useState("")
    const [showQ2, setshowQ2] = useState(false)
    const [autreQ2, setautreQ2] = useState("")
    const [q3, setq3] = useState("")
    const [q4, setq4] = useState("")
    const [q5, setq5] = useState("")
    const [q6, setq6] = useState("")
    const [q7, setq7] = useState("")
    const [showq7, setshowq7] = useState(false)
    const [autreq7, setautreq7] = useState("")
    const [q8, setq8] = useState("")
    const [q9, setq9] = useState("")
    const [q10, setq10] = useState("")
    const [q11, setq11] = useState("")
    const [q12_1, setq12_1] = useState("")
    const [q12_2, setq12_2] = useState("")
    const [q12_3, setq12_3] = useState("")
    const [q12_1autre, setq12_1autre] = useState("")
    const [q12_2autre, setq12_2autre] = useState("")
    const [q12_3autre, setq12_3autre] = useState("")
    const [q12_commentaire1, setq12_commentaire1] = useState("")
    const [q12_commentaire2, setq12_commentaire2] = useState("")
    const [q12_commentaire3, setq12_commentaire3] = useState("")



    const [q13, setq13] = useState("")

    const veriForm = (e) => {
        setemail(e.target.value)
        // don't remember from where i copied this code, but this works.
        if (re.test(mail.current.value)) {
            setvalide(true)
        } else {
            setvalide("")
        }
    }
    // change handling: Q2
    const handleChange = e => {
        const target = e.target;
        if (target.value !== "autre") {
            setq2(target.value);
            setshowQ2(false)
        } else if (target.value === "autre") {
            setq2(target.value);
            setshowQ2(true)
        }
    };
    // change handling: Q3
    const handleChangeQ3 = e => {
        const target = e.target;
        if (target.value) {
            setq3(target.value);
        }
    };
    // change handling: Q4
    const handleChangeQ4 = e => {
        const target = e.target;
        if (target.value) {
            setq4(target.value);
        }
    };

    // change handling: Q5
    const handleChangeQ5 = e => {
        const target = e.target;
        if (target.value) {
            setq5(target.value);
        }
    };
    // change handling: Q6
    const handleChangeQ6 = e => {
        const target = e.target;
        if (target.value) {
            setq6(target.value);
        }
    };
    // change handling: Q7
    const handleChangeQ7 = e => {
        const target = e.target;
        if (target.value !== "autre") {
            setq7(target.value);
            setshowq7(false)
        } else if (target.value === "autre") {
            setq7(target.value);
            setshowq7(true)
        }
    };
    // change handling: q8
    const handleChangeQ8 = e => {
        const target = e.target;
        if (target.value) {
            setq8(target.value);
        }
    };
    // change handling: q8
    const handleChangeQ11 = e => {
        const target = e.target;
        if (target.value) {
            setq11(target.value);
        }
    };

    // change handling: q12_1autre
    const handleChangeQ12_1autre = e => {
        const target = e.target;
        if (target.value) {
            setq12_1autre(target.value);
        }
    };

    // change handling: q12_1autre
    const handleChangeQ12_2autre = e => {
        const target = e.target;
        if (target.value) {
            setq12_2autre(target.value);
        }
    };
    // change handling: q12_1autre
    const handleChangeQ12_3autre = e => {
        const target = e.target;
        if (target.value) {
            setq12_3autre(target.value);
        }
    };
    const saveData = () => {
        if (email === "" || !re.test(email)) {
            setvalide(false)
            Swal.fire({
                icon: 'info',
                title: 'Votre Email',
                text: 'Veuillez bien saisir votre E-Mail , SVP!',
            })
        } else if (nomPrenom === "") {
            setvalideNom(false)
            Swal.fire({
                icon: 'info',
                title: 'Votre Nom',
                text: 'Veuillez bien saisir votre nom , SVP!',
            })
        } else if (ste === "") {
            Swal.fire({
                icon: 'info',
                title: 'Votre Nom',
                text: 'Veuillez bien saisir votre Nom de société SVP !',
            })
        }
        else if (q2 === "" || q3 === "" || q4 === "" || q5 === "" || q6 === "" || q7 === "" || q8 === "" || q10 === "" || q11 === "") {
            Swal.fire({
                icon: 'info',
                title: 'Les champs obligatoire',
                text: 'Veuillez bien remplire les champs obligatoires SVP !',
            })
        }
        else {
            const data = {
                nomprenom: nomPrenom,
                email: email,
                societe: ste,
                q2: q2 !== 'autre' ? q2 : q2 + " : " + autreQ2,
                q3: q3,
                q4: q4,
                q5: q5,
                q6: q6,
                q7: q7 !== 'autre' ? q7 : q7 + " : " + autreq7 ,
                q8: q8,
                q9: q9,
                q10: q10,
                q11: q11,
                q12_1: q12_1 === "" ? 'NULL' : q12_1,
                q12_2: q12_2 === "" ? 'NULL' : q12_2,
                q12_3: q12_3 === "" ? 'NULL' : q12_3,
                q12_1autre: q12_1autre,
                q12_2autre: q12_2autre,
                q12_3autre: q12_3autre,
                q13: q13,
                q12_commentaire1: q12_commentaire1,
                q12_commentaire2: q12_commentaire2,
                q12_commentaire3: q12_commentaire3,
                date: new Date()
            }
            console.log(data)
            addData(data)
                .then((result) => {
                    console.log(result)
                    if (result?.data.success === true) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Merci',
                            text: "Merci d'avoir pris le temps de répondre a notre formulaire ! L'équipe Clediss vous souhaite une bonne et heureuse année  ",
                        })
                    } else if (result?.data.success === false) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Erreur',
                            text: "Erreur d'envoi des réponses  ",
                        })
                    }
                }).catch((err) => {
                    console.log(err)
                });
        }
    }
    return (
        <div>
            <HeaderFormulaire />
            {/* row opened */}
            <div className="row">
                <div className="col-md">
                    <div className="card overflow-hidden">
                        <div className="card-header">
                            <h3 className="card-title mt-3 text-azure"> Questionnaire de satisfaction </h3>
                        </div>
                        <div className="card-body">
                            {/**  bloc  : Information Génerale */}
                            <h4 className="mb-5 fs-15"><i className='icon icon-info text-azure mr-1' /> 1- Informations Génerale </h4>
                            <div className="row mb-2">
                                <div className="col-md-6">
                                    <div className="form-group overflow-hidden">
                                        <label> Nom et Prénom  <span className='text-danger'>*</span></label>
                                        <input type="text" className={valideNom ? "form-control is-valid state-valid" : (valideNom === false ? "form-control is-invalid state-invalid" : " form-control")} value={nomPrenom} onChange={(e) => setNomPrenom(e.target.value)}
                                            name="example-text-input-valid" placeholder="Nom et Prénom .." />

                                    </div>

                                </div>
                                <div className="col-md-6">
                                    <div className="form-group overflow-hidden">
                                        <label> E-Mail <span className='text-danger'>*</span></label>
                                        <input ref={mail} type="email" className={valide ? "form-control is-valid state-valid" : (valide === false ? "form-control is-invalid state-invalid" : " form-control")} value={email} onChange={(e) => veriForm(e)}
                                            name="example-text-input-valid" placeholder="E-mail.." />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group overflow-hidden">
                                        <label> Nom Société    <span className='text-danger'>*</span></label>
                                        <input type="text" className='form-control' value={ste} onChange={(e) => setste(e.target.value)}
                                            name="example-text-input-valid" placeholder="Votre Réponse .." />
                                    </div>
                                </div>
                            </div>
                            {/**  bloc  : 2  - 3 */}
                            <div className="row">
                                <div className="col-md-6">
                                    <h4 className="mb-5 fs-15">
                                        <i className='icon icon-question text-azure mr-1' /> 2- A quelle fréquence contactez-vous notre équipe support ?   <span className='text-danger'>*</span></h4>

                                    <div className="form-group form-elements">
                                        <div className="custom-controls-stacked">
                                            <label className="custom-control custom-radio">
                                                <input type="radio" className="custom-control-input" name="question2" onChange={(e) => handleChange(e)} value={'Quotidiennement'} />
                                                <span className="custom-control-label">Quotidiennement</span>
                                            </label>
                                            <label className="custom-control custom-radio">
                                                <input type="radio" className="custom-control-input" name="question2" onChange={(e) => handleChange(e)} value={"Hebdomadairement"} />
                                                <span className="custom-control-label">Hebdomadairement</span>
                                            </label>
                                            <label className="custom-control custom-radio">
                                                <input type="radio" className="custom-control-input" name="question2" onChange={(e) => handleChange(e)} value={"Mensuellement"} />
                                                <span className="custom-control-label">Mensuellement</span>
                                            </label>
                                            <label className="custom-control custom-radio">
                                                <input type="radio" className="custom-control-input" name="question2" onChange={(e) => handleChange(e)} value={"autre"} />
                                                <span className="custom-control-label">Autre </span>
                                            </label>
                                            {showQ2 ? <input type="text" value={autreQ2} onChange={(e) => setautreQ2(e.target.value)} className="form-control" name="question2" placeholder="Autre ... " /> : null}


                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <h4 className="mb-5 fs-15">
                                        <i className='icon icon-question text-azure mr-1' /> 3- Globalement êtes-vous satisfait de notre service client ?  <span className='text-danger'>*</span> </h4>

                                    <div className="form-group form-elements">
                                        <div className="form-group form-elements">
                                            <div className="custom-controls-stacked">
                                                <label className="custom-control custom-radio">
                                                    <input type="radio" className="custom-control-input" name="question3" onChange={(e) => handleChangeQ3(e)} value={1} />
                                                    <span className="custom-control-label">Pas du tout satisfait(e)</span>
                                                </label>
                                                <label className="custom-control custom-radio">
                                                    <input type="radio" className="custom-control-input" name="question3" onChange={(e) => handleChangeQ3(e)} value={2} />
                                                    <span className="custom-control-label">Moyen</span>
                                                </label>
                                                <label className="custom-control custom-radio">
                                                    <input type="radio" className="custom-control-input" name="question3" onChange={(e) => handleChangeQ3(e)} value={3} />
                                                    <span className="custom-control-label">Bien</span>
                                                </label>
                                                <label className="custom-control custom-radio">
                                                    <input type="radio" className="custom-control-input" name="question3" onChange={(e) => handleChangeQ3(e)} value={4} />
                                                    <span className="custom-control-label">Trés Bien</span>
                                                </label>
                                                <label className="custom-control custom-radio">
                                                    <input type="radio" className="custom-control-input" name="question3" onChange={(e) => handleChangeQ3(e)} value={5} />
                                                    <span className="custom-control-label">Trés Satisfait(e) </span>

                                                </label>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/**  bloc  : 4  - 5 */}
                            <div className="row">
                                <div className="col-md-6">
                                    <h4 className="mb-5 fs-15">
                                        <i className='icon icon-question text-azure mr-1' /> 4- Concernant notre accueil téléphonique vous êtes plutôt : ?  <span className='text-danger'>*</span> </h4>
                                    <div className="form-group form-elements">

                                        <div className="custom-controls-stacked">
                                            <div className="form-group form-elements">
                                                <div className="custom-controls-stacked">
                                                    <label className="custom-control custom-radio">
                                                        <input type="radio" className="custom-control-input" name="question4" onChange={(e) => handleChangeQ4(e)} value={1} />
                                                        <span className="custom-control-label">Pas du tout satisfait(e)</span>
                                                    </label>
                                                    <label className="custom-control custom-radio">
                                                        <input type="radio" className="custom-control-input" name="question4" onChange={(e) => handleChangeQ4(e)} value={2} />
                                                        <span className="custom-control-label">Moyen</span>
                                                    </label>
                                                    <label className="custom-control custom-radio">
                                                        <input type="radio" className="custom-control-input" name="question4" onChange={(e) => handleChangeQ4(e)} value={3} />
                                                        <span className="custom-control-label">Bien</span>
                                                    </label>
                                                    <label className="custom-control custom-radio">
                                                        <input type="radio" className="custom-control-input" name="question4" onChange={(e) => handleChangeQ4(e)} value={4} />
                                                        <span className="custom-control-label">Trés Bien</span>
                                                    </label>
                                                    <label className="custom-control custom-radio">
                                                        <input type="radio" className="custom-control-input" name="question4" onChange={(e) => handleChangeQ4(e)} value={5} />
                                                        <span className="custom-control-label">Trés Satisfait(e) </span>

                                                    </label>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <h4 className="mb-5 fs-15">
                                        <i className='icon icon-question text-azure mr-1' /> 5- Temps de traitement des réclamations ?  <span className='text-danger'>*</span> </h4>
                                    <div className="form-group form-elements">
                                        <div className="form-group form-elements">
                                            <div className="custom-controls-stacked">
                                                <label className="custom-control custom-radio">
                                                    <input type="radio" className="custom-control-input" name="question5" onChange={(e) => handleChangeQ5(e)} value={1} />
                                                    <span className="custom-control-label">Pas du tout satisfait(e)</span>
                                                </label>
                                                <label className="custom-control custom-radio">
                                                    <input type="radio" className="custom-control-input" name="question5" onChange={(e) => handleChangeQ5(e)} value={2} />
                                                    <span className="custom-control-label">Moyen</span>
                                                </label>
                                                <label className="custom-control custom-radio">
                                                    <input type="radio" className="custom-control-input" name="question5" onChange={(e) => handleChangeQ5(e)} value={3} />
                                                    <span className="custom-control-label">Bien</span>
                                                </label>
                                                <label className="custom-control custom-radio">
                                                    <input type="radio" className="custom-control-input" name="question5" onChange={(e) => handleChangeQ5(e)} value={4} />
                                                    <span className="custom-control-label">Trés Bien</span>
                                                </label>
                                                <label className="custom-control custom-radio">
                                                    <input type="radio" className="custom-control-input" name="question5" onChange={(e) => handleChangeQ5(e)} value={5} />
                                                    <span className="custom-control-label">Trés Satisfait(e) </span>

                                                </label>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/**  bloc  : 6  - 7 */}
                            <div className="row">
                                <div className="col-md-6">
                                    <h4 className="mb-5 fs-15">
                                        <i className='icon icon-question text-azure mr-1' /> 6- Concernant le temps consacré pour les formations administratives ?  <span className='text-danger'>*</span> </h4>
                                    <div className="form-group form-elements">

                                        <div className="custom-controls-stacked">
                                            <div className="form-group form-elements">
                                                <div className="custom-controls-stacked">
                                                    <label className="custom-control custom-radio">
                                                        <input type="radio" className="custom-control-input" name="question6" onChange={(e) => handleChangeQ6(e)} value={1} />
                                                        <span className="custom-control-label">Pas du tout satisfait(e)</span>
                                                    </label>
                                                    <label className="custom-control custom-radio">
                                                        <input type="radio" className="custom-control-input" name="question6" onChange={(e) => handleChangeQ6(e)} value={2} />
                                                        <span className="custom-control-label">Moyen</span>
                                                    </label>
                                                    <label className="custom-control custom-radio">
                                                        <input type="radio" className="custom-control-input" name="question6" onChange={(e) => handleChangeQ6(e)} value={3} />
                                                        <span className="custom-control-label">Bien</span>
                                                    </label>
                                                    <label className="custom-control custom-radio">
                                                        <input type="radio" className="custom-control-input" name="question6" onChange={(e) => handleChangeQ6(e)} value={4} />
                                                        <span className="custom-control-label">Trés Bien</span>
                                                    </label>
                                                    <label className="custom-control custom-radio">
                                                        <input type="radio" className="custom-control-input" name="question6" onChange={(e) => handleChangeQ6(e)} value={5} />
                                                        <span className="custom-control-label">Trés Satisfait(e) </span>

                                                    </label>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <h4 className="mb-5 fs-15">
                                        <i className='icon icon-question text-azure mr-1' /> 7- Nos solutions vous aide-t-elles à atteindre vos objectifs ?  ?   <span className='text-danger'>*</span> </h4>
                                    <div className="form-group form-elements">
                                        <div className="form-group form-elements">
                                            <div className="custom-controls-stacked">
                                                <label className="custom-control custom-radio">
                                                    <input type="radio" className="custom-control-input" name="question7" onChange={(e) => handleChangeQ7(e)} value={"oui"} />
                                                    <span className="custom-control-label"> Oui </span>
                                                </label>
                                                <label className="custom-control custom-radio">
                                                    <input type="radio" className="custom-control-input"  name="question7" onChange={(e) => handleChangeQ7(e)} value={"non"} />
                                                    <span className="custom-control-label">Non </span>
                                                </label>
                                                <label className="custom-control custom-radio">
                                                    <input type="radio" className="custom-control-input" name="question7" onChange={(e) => handleChangeQ7(e)} value={"autre"} />
                                                    <span className="custom-control-label">Autre </span>
                                                </label>
                                                {showq7 ? <input type="text" className="form-control" name="question7" placeholder="Autre ... " value={autreq7} onChange={(e) => setautreq7(e.target.value)} /> : null}


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/**  bloc  : 8  - 9 */}
                            <div className="row">
                                <div className="col-md-6">
                                    <h4 className="mb-5 fs-15">
                                        <i className='icon icon-question text-azure mr-1' /> 8- Qu'elle est votre opinion concernant les M.A.J continues ? <span className='text-danger'>*</span> </h4>
                                    <div className="form-group form-elements">

                                        <div className="custom-controls-stacked">
                                            <div className="form-group form-elements">
                                            <div className="custom-controls-stacked">
                                                    <label className="custom-control custom-radio">
                                                        <input type="radio" className="custom-control-input" name="question8" onChange={(e) => handleChangeQ8(e)} value={1} />
                                                        <span className="custom-control-label">Pas du tout satisfait(e)</span>
                                                    </label>
                                                    <label className="custom-control custom-radio">
                                                        <input type="radio" className="custom-control-input" name="question8" onChange={(e) => handleChangeQ8(e)} value={2} />
                                                        <span className="custom-control-label">Moyen</span>
                                                    </label>
                                                    <label className="custom-control custom-radio">
                                                        <input type="radio" className="custom-control-input" name="question8" onChange={(e) => handleChangeQ8(e)} value={3} />
                                                        <span className="custom-control-label">Bien</span>
                                                    </label>
                                                    <label className="custom-control custom-radio">
                                                        <input type="radio" className="custom-control-input" name="question8" onChange={(e) => handleChangeQ8(e)} value={4} />
                                                        <span className="custom-control-label">Trés Bien</span>
                                                    </label>
                                                    <label className="custom-control custom-radio">
                                                        <input type="radio" className="custom-control-input" name="question8" onChange={(e) => handleChangeQ8(e)} value={5} />
                                                        <span className="custom-control-label">Trés Satisfait(e) </span>

                                                    </label>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <h4 className="mb-5 fs-15">
                                        <i className='icon icon-question text-azure mr-1' /> 9 Qu’est-ce que vous aimez le plus dans nos solution?  <span className='text-danger'>*</span> </h4>
                                    <div className="form-group form-elements">
                                        <div className="form-group form-elements">
                                            <div className="custom-controls-stacked">

                                                <textarea type="text" value={q9} onChange={(e) => setq9(e.target.value)} className="form-control" placeholder='Votre Réponse' style={{ height: "120px" }} />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/**  bloc  : 10  - 11 */}
                            <div className="row">

                                <div className="col-md-6">
                                    <h4 className="mb-5 fs-15">
                                        <i className='icon icon-question text-azure mr-1' /> 10- Si vous pouviez améliorer quelque chose, que serait-ce ?  <span className='text-danger'>*</span> </h4>
                                    <div className="form-group form-elements">
                                        <div className="form-group form-elements">
                                            <div className="custom-controls-stacked">

                                                <textarea type="text" value={q10} onChange={(e) => setq10(e.target.value)} className="form-control" placeholder='Votre Réponse' style={{ height: "120px" }} />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <h4 className="mb-5 fs-15">
                                        <i className='icon icon-question text-azure mr-1' /> 11 - Quelle est la probabilité que vous recommandiez notre service client à un proche ?  <span className='text-danger'>*</span> </h4>
                                    <div className="form-group form-elements">

                                        <div className="custom-controls-stacked">
                                            <div className="form-group form-elements">
                                                <div className="custom-controls-stacked">
                                                    <label className="custom-control custom-radio">
                                                        <input type="radio" className="custom-control-input" name="question11" onChange={(e) => handleChangeQ11(e)} value={1} />
                                                        <span className="custom-control-label">Pas du tout satisfait(e)</span>
                                                    </label>
                                                    <label className="custom-control custom-radio">
                                                        <input type="radio" className="custom-control-input" name="question11" onChange={(e) => handleChangeQ11(e)} value={2} />
                                                        <span className="custom-control-label">Moyen</span>
                                                    </label>
                                                    <label className="custom-control custom-radio">
                                                        <input type="radio" className="custom-control-input" name="question11" onChange={(e) => handleChangeQ11(e)} value={3} />
                                                        <span className="custom-control-label">Bien</span>
                                                    </label>
                                                    <label className="custom-control custom-radio">
                                                        <input type="radio" className="custom-control-input" name="question11" onChange={(e) => handleChangeQ11(e)} value={4} />
                                                        <span className="custom-control-label">Trés Bien</span>
                                                    </label>
                                                    <label className="custom-control custom-radio">
                                                        <input type="radio" className="custom-control-input" name="question11" onChange={(e) => handleChangeQ11(e)} value={5} />
                                                        <span className="custom-control-label">Trés Satisfait(e) </span>

                                                    </label>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/**  bloc  : 12  - 13 */}
                            <div className="row">
                                <div className="col-lg-12">
                                    <h4 className="mb-5 fs-15">
                                        <i className='icon icon-question text-azure mr-1' /> 12- Votre vis-à-vis ?  <span className='text-danger'>*</span> </h4>
                                    <ul className="list-group">

                                        <li className="list-group-item">
                                            Ahmed
                                            <div className="material-switch pull-right">
                                                <input id="someSwitchOptionPrimary" value="ahmed" onChange={(e) => setq12_1(e.target.value)} name="someSwitchOption001" type="checkbox" />
                                                <label htmlFor="someSwitchOptionPrimary" className="label-info" />
                                            </div>
                                            {q12_1 === "ahmed" ?
                                                <div className='row'>
                                                    <div className="custom-controls-stacked">

                                                        <label className="custom-control custom-radio">
                                                            <input type="radio" className="custom-control-input" name="q12_1" onChange={(e) => handleChangeQ12_1autre(e)} value={1} />
                                                            <span className="custom-control-label">Pas du tout satisfait(e)</span>
                                                        </label>
                                                        <label className="custom-control custom-radio">
                                                            <input type="radio" className="custom-control-input" name="q12_1" onChange={(e) => handleChangeQ12_1autre(e)} value={2} />
                                                            <span className="custom-control-label">Moyen</span>
                                                        </label>
                                                        <label className="custom-control custom-radio">
                                                            <input type="radio" className="custom-control-input" name="q12_1" onChange={(e) => handleChangeQ12_1autre(e)} value={3} />
                                                            <span className="custom-control-label">Bien</span>
                                                        </label>
                                                        <label className="custom-control custom-radio">
                                                            <input type="radio" className="custom-control-input" name="q12_1" onChange={(e) => handleChangeQ12_1autre(e)} value={4} />
                                                            <span className="custom-control-label">Trés Bien</span>
                                                        </label>
                                                        <label className="custom-control custom-radio">
                                                            <input type="radio" className="custom-control-input" name="q12_1" onChange={(e) => handleChangeQ12_1autre(e)} value={5} />
                                                            <span className="custom-control-label">Trés Satisfait(e) </span>

                                                        </label>
                                                    </div>

                                                     <textarea className="ml-8" type="text" value={q12_commentaire1} onChange={(e) => setq12_commentaire1(e.target.value)} placeholder='Votre Avis ... ' style={{width :"300px"}} />

                                                </div>
                                                : null}
                                        </li>
                                        <li className="list-group-item">
                                            Mariem
                                            <div className="material-switch pull-right">
                                                <input id="someSwitchOptionSuccess" value="Mariem" onChange={(e) => setq12_2(e.target.value)} name="someSwitchOption001" type="checkbox" />
                                                <label htmlFor="someSwitchOptionSuccess" className="label-info" />

                                            </div>
                                            {q12_2 === "Mariem" ?
                                                <div className="row">
                                                <div className="custom-controls-stacked">

                                                    <label className="custom-control custom-radio">
                                                        <input type="radio" className="custom-control-input" name="q12_2" onChange={(e) => handleChangeQ12_2autre(e)} value={1} />
                                                        <span className="custom-control-label">Pas du tout satisfait(e)</span>
                                                    </label>
                                                    <label className="custom-control custom-radio">
                                                        <input type="radio" className="custom-control-input" name="q12_2" onChange={(e) => handleChangeQ12_2autre(e)} value={2} />
                                                        <span className="custom-control-label">Moyen</span>
                                                    </label>
                                                    <label className="custom-control custom-radio">
                                                        <input type="radio" className="custom-control-input" name="q12_2" onChange={(e) => handleChangeQ12_2autre(e)} value={3} />
                                                        <span className="custom-control-label">Bien</span>
                                                    </label>
                                                    <label className="custom-control custom-radio">
                                                        <input type="radio" className="custom-control-input" name="q12_2" onChange={(e) => handleChangeQ12_2autre(e)} value={4} />
                                                        <span className="custom-control-label">Trés Bien</span>
                                                    </label>
                                                    <label className="custom-control custom-radio">
                                                        <input type="radio" className="custom-control-input" name="q12_2" onChange={(e) => handleChangeQ12_2autre(e)} value={5} />
                                                        <span className="custom-control-label">Trés Satisfait(e) </span>

                                                    </label>

                                                </div> 
                                                <textarea className="ml-8" type="text" value={q12_commentaire2} onChange={(e) => setq12_commentaire2(e.target.value)} placeholder='Votre Avis ... ' style={{width :"300px"}} />

                                            </div> : null}
                                        </li>
                                        <li className="list-group-item">
                                            Khoubaib
                                            <div className="material-switch pull-right">
                                                <input id="someSwitchOptionInfo" value="Khoubaieb" onChange={(e) => setq12_3(e.target.value)} name="someSwitchOption001" type="checkbox" />
                                                <label htmlFor="someSwitchOptionInfo" className="label-info" />
                                            </div>
                                            {q12_3 === "Khoubaieb" ?
                                            <div className='row'>
                                                <div className="custom-controls-stacked">

                                                    <label className="custom-control custom-radio">
                                                        <input type="radio" className="custom-control-input" name="q12_3" onChange={(e) => handleChangeQ12_3autre(e)} value={1} />
                                                        <span className="custom-control-label">Pas du tout satisfait(e)</span>
                                                    </label>
                                                    <label className="custom-control custom-radio">
                                                        <input type="radio" className="custom-control-input" name="q12_3" onChange={(e) => handleChangeQ12_3autre(e)} value={2} />
                                                        <span className="custom-control-label">Moyen</span>
                                                    </label>
                                                    <label className="custom-control custom-radio">
                                                        <input type="radio" className="custom-control-input" name="q12_3" onChange={(e) => handleChangeQ12_3autre(e)} value={3} />
                                                        <span className="custom-control-label">Bien</span>
                                                    </label>
                                                    <label className="custom-control custom-radio">
                                                        <input type="radio" className="custom-control-input" name="q12_3" onChange={(e) => handleChangeQ12_3autre(e)} value={4} />
                                                        <span className="custom-control-label">Trés Bien</span>
                                                    </label>
                                                    <label className="custom-control custom-radio">
                                                        <input type="radio" className="custom-control-input" name="q12_3" onChange={(e) => handleChangeQ12_3autre(e)} value={5} />
                                                        <span className="custom-control-label">Trés Satisfait(e) </span>

                                                    </label>

                                                </div>
                                                <textarea className="ml-8" type="text" value={q12_commentaire3} onChange={(e) => setq12_commentaire3(e.target.value)} placeholder='Votre Avis ... ' style={{width :"300px"}} />

                                            </div> : null}
                                        </li>

                                    </ul>
                                </div>


                                <div className="col-md-6">
                                    *
                                    <div className="form-group form-elements">
                                        <div className="form-group form-elements">

                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/**  bloc  : 14  */}
                            <div className="row mb-2">
                                <div className="col-md-12">
                                    <div className="form-group overflow-hidden">
                                        <h4 className="mb-5 fs-15">
                                            <i className='icon icon-question text-azure mr-1' /> 13 -  Vos remarques et suggestions  </h4>
                                        <textarea type="text" className='form-control'
                                            name="example-text-input-valid" placeholder="Votre Réponse .." value={q13} onChange={(e) => setq13(e.target.value)} />

                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className='card-footer'>
                            <div className="row float-right">
                                <button className='btn btn-primary' onClick={() => saveData()}> <i className='ti ti-save mr-1' /> Envoyer  les réponses </button>
                            </div>
                        </div>
                    </div>
                </div>{/* col end */}
            </div>

            {/* row closed */}

        </div >
    )
}
