import React from 'react'
import EmptyHeader from '../Shared/EmptyHeader'
import Footer from '../Shared/Footer'

export default function EmptyLayout(props) {
    return (<div>
        <EmptyHeader />

        <div className='container'>
            <React.Fragment>{props.children}</React.Fragment>

        </div>
        <Footer />
    </div>
    )
}
